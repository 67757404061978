import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { Box, SxProps } from '@mui/material'

import logo from 'assets/images/logo-new.png'
import logoNoColor from 'assets/images/logo-no-color.png'
import { appPaths } from 'routes'
import { Image } from 'components/common'

interface LogoProps {
    preventClick?: boolean
    onClick?: () => void
    height?: string
    sx?: SxProps
    noColor?: boolean
}

const Logo = ({
    preventClick = false,
    height = '65px',
    sx = {},
    noColor = false,
    onClick,
}: LogoProps): JSX.Element => {
    const navigate = useNavigate()

    const [image, setImage] = useState(noColor ? logoNoColor : logo)

    return (
        <Box
            sx={{
                ...sx,
                height,
                // width: '100%',
            }}
        >
            <Image
                onClick={() => {
                    if (onClick) {
                        onClick()
                    } else {
                        navigate(`../${appPaths.home}`)
                    }
                }}
                onMouseEnter={() => {
                    if (noColor) {
                        setImage(logo)
                    }
                }}
                onMouseLeave={() => {
                    if (noColor) {
                        setImage(logoNoColor)
                    }
                }}
                src={image}
                alt="logo"
                sx={{
                    cursor: 'pointer',
                    height: '100%',
                    pointerEvents: preventClick ? 'none' : 'initial',
                }}
            />
        </Box>
    )
}

export default Logo
