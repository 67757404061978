import React, { ReactNode } from 'react'

import { Button, ButtonProps, Typography, useMediaQuery } from '@mui/material'

import cup from 'assets/images/SP-CUP.png'
import { Image } from 'components/common'

interface BrandButtonProps extends ButtonProps {
    children: ReactNode
}

const BrandButton = ({ children, ...props }: BrandButtonProps): JSX.Element => {
    const lessThan700px = useMediaQuery('(max-width:700px)')

    return (
        <Button
            variant="contained"
            sx={theme => ({
                color: '#fff',
                padding: lessThan700px ? '4px 12px' : '6px 18px',
                borderRadius: '6px',
                backgroundColor: '#181818',
                fontSize: lessThan700px ? '14px' : '20px',
                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.3)',
                border: '1px solid #404040',
                gap: '10px',
                '& .MuiSvgIcon-root': {
                    fontSize: 'max(min(1vw, 29px), 18px)',
                },
            })}
            startIcon={
                <Image
                    sx={{ width: lessThan700px ? '13px' : '17px' }}
                    src={cup}
                />
            }
            {...props}
        >
            <Typography fontSize={lessThan700px ? '18px' : '22px'}>
                {children}
            </Typography>
        </Button>
    )
}

export default BrandButton
