import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
    root: {
        minWidth: '400px',
        padding: '40px',
    },
    title: {
        fontSize: '20px',
        maxWidth: '500px',
    },
    caption: {
        fontSize: '16px',
        maxWidth: '500px',
    },
    name: {
        fontSize: '24px',
        maxWidth: '400px',
    },
    approveButton: {
        '& .MuiTypography-root': {
            fontSize: '16px',
            color: theme.colors.background,
        },
    },
    resource: {
        borderRadius: '8px',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.3)',
        border: '1px solid #222',
        background: 'rgba(0, 0, 0, 0.48)',
        padding: '15px',
    },
    compact: {
        padding: '10px',
        '& $name': {
            fontSize: '16px',
        },
        '& $preview': {
            width: '90px',
            height: '60px',
        },
    },
    preview: {
        width: '180px',
        height: '120px',
        objectFit: 'cover',
    },
}))
