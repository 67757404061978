import styles from './index.styles'

import React from 'react'

import {
    Dialog as MuiDialog,
    DialogContent,
    IconButton,
    Slide,
    SxProps,
    useMediaQuery,
} from '@mui/material'
import { CloseOutlined } from '@mui/icons-material'
import { TransitionProps } from '@mui/material/transitions'

import DialogButtons from './Buttons'

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

export interface DialogProps {
    open?: boolean
    closable?: boolean
    onClose: () => void
    onSubmit?: () => void
    children?: JSX.Element
    sx?: SxProps
    fullWidth?: boolean
}

const Dialog = (props: DialogProps): JSX.Element => {
    const lessThan700px = useMediaQuery('(max-width:700px)')

    const classes = styles()
    const {
        children,
        onSubmit,
        onClose,
        open = true,
        closable = true,
        sx = {},
        fullWidth = false,
    } = props
    return (
        <MuiDialog
            maxWidth={false}
            open={open}
            fullWidth={fullWidth}
            onClose={() => {
                if (closable) {
                    onClose()
                }
            }}
            className={classes.root}
            TransitionComponent={Transition}
            PaperProps={{
                className: classes.paper,
            }}
            sx={{
                ...(lessThan700px && {
                    '& .MuiDialog-paper': {
                        margin: '0',
                    },
                }),
                ...sx,
            }}
        >
            <DialogContent className={classes.content}>
                {closable && (
                    <IconButton
                        onClick={onClose}
                        className={classes.closeButton}
                    >
                        <CloseOutlined />
                    </IconButton>
                )}
                {children}
            </DialogContent>
            {onSubmit && (
                <DialogButtons
                    removePaddings={false}
                    onSubmit={onSubmit}
                    onCancel={onClose}
                />
            )}
        </MuiDialog>
    )
}

export default Dialog
