import styles from './index.styles'

import React, { useState } from 'react'

import { t } from 'i18next'
import { Button, Stack, Typography } from '@mui/material'
import classnames from 'classnames'

import { CourseType, ModuleType, LessonType } from 'api/generated'
import { PrimaryButton, SecondaryButton } from '../../buttons'

export interface ConfirmationProps<T> {
    onApprove: () => void
    onReject: () => void
    data: T
    type?: 'lesson' | 'module' | 'course'
}

const Confirmation = <T extends ModuleType | CourseType | LessonType>({
    onApprove,
    onReject,
    data,
    type,
}: ConfirmationProps<T>): JSX.Element => {
    const classes = styles()

    // const renderModules = (course: CourseType) => {
    //     const modules = course.modules?.edges.map(n => n?.node)
    //
    //     return (
    //         <Stack gap="10px">
    //             {modules.map(m => (
    //                 <Stack />
    //             ))}
    //         </Stack>
    //     )
    // }

    const renderResource = (
        data: ModuleType | CourseType | LessonType,
        compact = false
    ) => {
        const image =
            // eslint-disable-next-line no-underscore-dangle
            data.__typename === 'ModuleType'
                ? null
                : (data as LessonType).videoPreviewUrl
                ? (data as LessonType).videoPreviewUrl
                : (data as CourseType).background

        return (
            <Stack
                key={data.id}
                direction="row"
                alignItems="center"
                gap="25px"
                className={classnames(classes.resource, {
                    [classes.compact]: compact,
                })}
            >
                {image && (
                    <img
                        className={classes.preview}
                        src={image as string}
                        alt=""
                    />
                )}
                <Typography className={classes.name}>{data.name}</Typography>
            </Stack>
        )
    }

    const renderNestedContent = (data: ModuleType | CourseType) => {
        const items: (ModuleType | LessonType)[] =
            type === 'course'
                ? ((data as CourseType).modules?.edges.map(
                      n => n?.node
                  ) as ModuleType[])
                : ((data as ModuleType).lessons?.edges.map(
                      n => n?.node
                  ) as LessonType[])

        return (
            <Stack gap="5px">{items?.map(i => renderResource(i, true))}</Stack>
        )
    }

    return (
        <Stack className={classes.root} gap="35px">
            <Typography className={classes.title}>
                {t('confirmation.title')}
            </Typography>
            {renderResource(data)}
            <Typography className={classes.caption}>
                {t('confirmation.caption')}:
            </Typography>
            {type !== 'lesson' &&
                renderNestedContent(data as CourseType | ModuleType)}
            <Stack justifyContent="flex-end" gap="15px" direction="row">
                <Button color="error" variant="outlined" onClick={onReject}>
                    <Typography>{t('confirmation.reject')}</Typography>
                </Button>
                <Button className={classes.approveButton} onClick={onApprove}>
                    <Typography> {t('confirmation.approve')}</Typography>
                </Button>
            </Stack>
        </Stack>
    )
}
export default Confirmation
