import styles from './index.styles'

import React from 'react'

import { Button, ButtonProps, Typography } from '@mui/material'
import cx from 'classnames'

const SecondaryButton = ({
    children,
    className = '',
    ...rest
}: ButtonProps): JSX.Element => {
    const classes = styles()

    return (
        <Button
            variant="outlined"
            className={cx(classes.root, { [className]: !!className })}
            {...rest}
        >
            <Typography variant="s1">{children}</Typography>
        </Button>
    )
}

export default SecondaryButton
