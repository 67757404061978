import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
    root: {
        color: theme.colors.black,
        padding: '16px 0',
        borderRadius: '5px',
        // borderColor: 'inherit',
    },
}))
