import { makeStyles } from '@mui/styles'

export default makeStyles({
    root: {
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    content: {
        padding: 0,
        display: 'flex',
        // minWidth: '450px',
        backgroundColor: '#181818',
        borderRadius: '8px',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.3)',
        border: '1px solid #222',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '@media (max-width:550px)': {
            minWidth: '0',
        },
    },
    paper: {
        background: '#181818',
        borderRadius: '8px',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        color: '#a9a9a9',
        zIndex: 2,
        background: '#2f2f2f',
        '@media (max-width:600px)': {
            '& .MuiSvgIcon-root': {
                fontSize: '15px',
            },
        },

        '&:hover': {
            background: '#181818',
        },
    },
})
