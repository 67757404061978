export enum ROLES {
    ADMIN = 1,
    CLIENT = 2,
    COACH = 3,
}

export const MAX_PHOTOS_LIMIT = 5
export const MAX_PHOTO_SIZE = 20971520 // 20MB
export const MAX_VIDEO_SIZE = 2147483648 // 2GB
export const PHOTO_ACCEPT_EXTENSIONS = {
    'image/png': ['.png'],
    'image/jpg': ['.jpg'],
    'image/jpeg': ['.jpeg'],
}
export const VIDEO_ACCEPT_EXTENSIONS = {
    'video/*': [],
}
