import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
    root: {
        cursor: 'pointer',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '10px',
        // background: 'white',
        padding: '6px',
        color: theme.colors.primary,
    },
    name: {
        fontSize: '22px',
        lineHeight: '22px',
        color: '#a3a3a3',
        '&:hover': {
            color: 'white',
        },
    },
}))
