import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles<Theme, { size: [number, number] }>(
    (theme: Theme) => ({
        container: {
            justifyContent: 'center',
            color: theme.colors.white,
        },
        imageOuter: {
            ...theme.typography.c1,
            width: ({ size }) => `${size[0]}px`,
            height: ({ size }) => `${size[1]}px`,
            borderRadius: '50%',
            overflow: 'hidden',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.colors.white,
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
    })
)
