import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        gap: '15px',
        padding: '0 8px',
    },
    questions: {},
    question: {
        fontSize: '15px',
        lineHeight: '18px',
        color: '#d5d3d3',
    },
    answer: {
        '& .MuiInputBase-root': {
            padding: '8px',
            fontSize: '14px',
            '&.Mui-disabled': {
                '& textarea': {
                    '-webkit-text-fill-color': `#fff !important`,
                },
            },
        },
    },
}))
