import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
    root: {
        color: theme.colors.black,
        padding: '16px 0',
        borderRadius: '0',
        backgroundColor: theme.colors.primary,
        position: 'relative',
        '&:hover': {
            backgroundColor: theme.colors.primaryLight,
        },
        '&[disabled]': {
            backgroundColor: theme.colors.secondaryDark,
            opacity: 0.6,
        },
    },
    text: {
        color: theme.colors.black,
    },
    active: {
        backgroundColor: theme.colors.primaryLight,
    },
}))
