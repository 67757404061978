import React from 'react'

import { TextField as MaterialTextField, Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'

interface SelectOption {
    key: string
    label: string
    value: number | string
}

interface SelectFieldProps {
    id: string
    name: string
    label: string
    value: number | string
    size?: 'small' | 'medium'
    variant?: 'standard' | 'filled' | 'outlined'
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    error?: boolean | undefined
    helperText?: string | false | undefined
    options: SelectOption[]
    disabled?: boolean
    disabledOptions?: string[]
    disabledCaption?: string
}

const SelectField = ({
    options,
    disabledOptions,
    disabledCaption,
    ...rest
}: SelectFieldProps): JSX.Element => {
    return (
        <MaterialTextField
            FormHelperTextProps={{
                variant: 'standard',
                sx: {
                    position: 'absolute',
                    bottom: '-25px',
                    left: 0,
                },
            }}
            select
            fullWidth
            variant="outlined"
            autoComplete="off"
            {...rest}
        >
            {options.map(option => {
                const isDisabledOption = disabledOptions
                    ? disabledOptions.includes(option.value as string)
                    : false

                return (
                    <MenuItem
                        key={option.key}
                        disabled={isDisabledOption}
                        value={option.value}
                    >
                        {option.label}
                        {disabledCaption && isDisabledOption && (
                            <Typography
                                sx={{
                                    marginLeft: '10px',
                                    fontSize: '14px',
                                }}
                            >
                                ({disabledCaption})
                            </Typography>
                        )}
                    </MenuItem>
                )
            })}
        </MaterialTextField>
    )
}

export default SelectField
