import React, { ReactNode } from 'react'

import { Box, SxProps } from '@mui/material'

interface PageLayoutProps {
    children?: JSX.Element | JSX.Element[] | ReactNode
    sx?: SxProps
}

const PageLayout: React.FC<PageLayoutProps> = ({
    children,
    sx = {},
}: PageLayoutProps) => {
    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                maxHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                ...sx,
            }}
        >
            {children}
        </Box>
    )
}

export default PageLayout
