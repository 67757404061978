import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        border: `1px dashed ${theme.colors.primary}`,
        cursor: 'pointer',
        borderRadius: '3px',
        '&:hover': {
            borderColor: theme.colors.primary,
        },
    },
    disabled: {
        cursor: 'initial',
        borderColor: 'transparent',
        '&:hover': {
            borderColor: 'transparent',
        },
    },
}))
