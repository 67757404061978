import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export default makeStyles((theme: Theme) => ({
    container: {},
    action: {
        background: '#181818',
        '& .MuiSvgIcon-root': {
            color: '#c7c7c7',
            padding: '5px',
        },
    },
    tooltip: {
        fontSize: '24px',
        maxWidth: 'none',
    },
}))
