import React, { Suspense } from 'react'

import { Box } from '@mui/material'
import { useRoutes } from 'react-router-dom'

import { ReactiveDialog } from 'components/common'
import { Loader } from 'components/layout'
import { appRoutes } from 'routes'
import { TokenStorage } from 'services'
import { useMe } from 'hooks/user'

const App = () => {
    const routes = useRoutes(appRoutes)
    const isAuthenticated = TokenStorage.isAuthenticated()
    const { loading } = useMe(!isAuthenticated)

    if (loading) {
        return <Loader />
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'stretch',
                '@media (max-width:550px)': {
                    height: 'auto',
                },
            }}
        >
            <ReactiveDialog />
            <Suspense fallback={<Loader />}>{routes}</Suspense>
        </Box>
    )
}

export default App
