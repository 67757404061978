import { makeStyles } from '@mui/styles'

export default makeStyles({
    container: {
        justifyContent: 'flex-end',
        padding: '24px',
    },
    contentCompensation: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
    },
})
