import React, { lazy } from 'react'

// PUBLIC
const HomePage = lazy(() => import('components/pages/storefront/HomePage'))
const ExpertPage = lazy(() => import('components/pages/storefront/ExpertPage'))
const CoursePage = lazy(() => import('components/pages/storefront/CoursePage'))
const WatchPage = lazy(() => import('components/pages/storefront/WatchPage'))
const ProfilePage = lazy(
    () => import('components/pages/storefront/ProfilePage')
)
const BecomeExpertPage = lazy(
    () => import('components/pages/storefront/BecomeExpertPage')
)

// AUTH
const LoginPage = lazy(() => import('components/pages/auth/LoginPage'))
const SignUpPage = lazy(() => import('components/pages/auth/SignUpPage'))
const ResetPasswordPage = lazy(
    () => import('components/pages/auth/ResetPasswordPage')
)
const ForgotPasswordPage = lazy(
    () => import('components/pages/auth/ForgotPasswordPage')
)
const EmailVerificationPage = lazy(
    () => import('components/pages/auth/EmailVerificationPage')
)
const AccountActivationPage = lazy(
    () => import('components/pages/auth/AccountActivationPage')
)

// BACKOFFICE
const BackofficePage = lazy(
    () => import('components/pages/backoffice/BackofficePage')
)
const FeedbackPage = lazy(
    () => import('components/pages/backoffice/FeedbackPage')
)
const ContentPage = lazy(
    () => import('components/pages/backoffice/ContentPage')
)
const BackofficeCoursePage = lazy(
    () => import('components/pages/backoffice/CoursePage')
)
const DashboardPage = lazy(
    () => import('components/pages/backoffice/DashboardPage')
)
const CourseAccessPage = lazy(
    () => import('components/pages/backoffice/CourseAccessPage')
)
const CourseGrantAccessPage = lazy(
    () => import('components/pages/backoffice/CourseGrantAccessPage')
)

// COMMON
const NotFoundPage = lazy(() => import('components/pages/common/NotFoundPage'))

const appPaths = {
    home: '/',
    login: 'login',
    signup: 'signup',
    resetPassword: 'reset-password',
    forgotPassword: 'forgot-password',
    emailVerification: 'email-verification',
    accountActivation: 'activate-account',
    expert: (coachId: string) => `/${coachId}`,
    courses: '/courses',
    course: (coachId: string, courseId: string) => `/${coachId}/${courseId}`,
    module: (courseId: string, moduleId: string, lessonId: string) =>
        `/courses/${courseId}/${moduleId}/${lessonId}`,
    notFound: '*',
    watch: () => `/watch`,
    backoffice: '/backoffice/*',
    becomeExpert: () => `become-expert`,
    profile: `profile`,
}

const backofficePaths = {
    backoffice: `/backoffice`,
    feedback: `feedback`,
    access: `access`,
    settings: `settings`,
    promotion: `promotion`,
    content: `content`,
    course: (courseId: string) => `content/${courseId}`,
    courseAccess: (courseId: string) => `content/${courseId}/access`,
    courseGrantAccess: (courseId: string) => `content/${courseId}/access/grant`,
    statistics: `statistics`,
    singletonAccessibility: `access/singletons`,
}

const backofficeRoutes = [
    // BACKOFFICE
    {
        index: true,
        element: <DashboardPage />,
    },
    {
        path: backofficePaths.feedback,
        element: <FeedbackPage />,
    },
    {
        path: backofficePaths.content,
        element: <ContentPage />,
    },
    {
        path: backofficePaths.course(':courseId'),
        element: <BackofficeCoursePage />,
    },
    {
        path: backofficePaths.courseAccess(':courseId'),
        element: <CourseAccessPage />,
    },
    {
        path: backofficePaths.courseGrantAccess(':courseId'),
        element: <CourseGrantAccessPage />,
    },

    // COMMON
    {
        path: appPaths.notFound,
        element: <NotFoundPage />,
    },
]

const appRoutes = [
    // PUBLIC
    {
        path: appPaths.home,
        element: <HomePage />,
    },
    {
        path: appPaths.expert(':coachId'),
        element: <ExpertPage />,
    },
    {
        path: appPaths.course(':coachId', ':courseId'),
        element: <CoursePage />,
    },
    {
        path: appPaths.watch(),
        element: <WatchPage />,
    },
    {
        path: appPaths.becomeExpert(),
        element: <BecomeExpertPage />,
    },
    {
        path: appPaths.profile,
        element: <ProfilePage />,
    },

    // AUTH
    {
        path: appPaths.login,
        element: <LoginPage />,
    },
    {
        path: appPaths.signup,
        element: <SignUpPage />,
    },
    {
        path: appPaths.forgotPassword,
        element: <ForgotPasswordPage />,
    },
    {
        path: appPaths.resetPassword,
        element: <ResetPasswordPage />,
    },
    {
        path: appPaths.emailVerification,
        element: <EmailVerificationPage />,
    },
    {
        path: appPaths.accountActivation,
        element: <AccountActivationPage />,
    },

    // BACKOFFICE
    {
        path: appPaths.backoffice,
        element: <BackofficePage />,
        children: backofficeRoutes,
    },

    // COMMON
    {
        path: appPaths.notFound,
        element: <NotFoundPage />,
    },
]

export { appRoutes, appPaths, backofficePaths, backofficeRoutes }
