import { styled } from '@mui/material/styles'

import Dialog from './Dialog'
import Avatar from './Avatar'
import Dropzone from './Dropzone'
import ReactiveDialog from './ReactiveDialog'
import Logo from './Logo'
import SocialsList, { SocialItem, Socials } from './SocialsList'
import Confirmation, { ConfirmationProps } from './Confirmation'
import CategoryList from './CategoryList'
import FeedbackPreview from './FeedbackPreview'
import FeedbackSample from './FeedbackSample'
import OwnerPanel from './OwnerPanel'
import FilePicker from './FilePicker'

const Image = styled('img')``
const Input = styled('input')``

export {
    Dialog,
    Avatar,
    ReactiveDialog,
    Dropzone,
    Logo,
    SocialsList,
    Socials,
    Confirmation,
    CategoryList,
    FeedbackPreview,
    FeedbackSample,
    Image,
    Input,
    OwnerPanel,
    FilePicker,
}

export type { SocialItem, ConfirmationProps }
