import React, { ReactNode } from 'react'

import { Box, Button, Stack } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ArrowBackIos } from '@mui/icons-material'

import spBackground from 'assets/images/SP-BACKGROUND.jpg'

interface AuthorizationPageLayoutProps {
    children?: JSX.Element | JSX.Element[] | ReactNode
    onBack?: () => void
    backButtonTitle?: string
}

const AuthorizationPageLayout: React.FC<AuthorizationPageLayoutProps> = ({
    children,
    onBack,
    backButtonTitle,
}: AuthorizationPageLayoutProps) => {
    return (
        <Stack
            justifyContent="center"
            alignItems="center"
            width="100%"
            padding="25px 0"
            sx={{
                background: `url(${spBackground})`,
                position: 'relative',
                height: '100vh',
            }}
        >
            {onBack && (
                <Button
                    variant="text"
                    startIcon={<ArrowBackIos />}
                    onClick={onBack}
                    sx={{
                        color: 'white',
                        position: 'absolute',
                        left: '15px',
                        top: '15px',
                        fontSize: '17px',
                        padding: '10px 15px',
                        '@media (max-width:550px)': {
                            fontSize: '13px',
                            padding: '6px 11px',
                            left: '10px',
                            top: '10px',
                        },
                    }}
                >
                    {backButtonTitle}
                </Button>
            )}
            <Box
                sx={{
                    maxWidth: '500px',
                    width: '100%',
                }}
            >
                {children}
            </Box>
        </Stack>
    )
}

export default AuthorizationPageLayout
