import React, { useState } from 'react'

import { t } from 'i18next'
import { Fab, Stack, Tooltip, Typography, Zoom } from '@mui/material'
import { AutoFixHigh, RoomPreferences } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

import { backofficePaths } from 'routes'
import { PinnedItems } from 'components/buttons'

interface OwnerPanelProps {
    onEditClick?: () => void
}

const OwnerPanel = ({ onEditClick }: OwnerPanelProps) => {
    const navigate = useNavigate()

    return (
        <Stack
            direction="row"
            alignItems="flex-end"
            gap="25px"
            sx={{
                position: 'fixed',
                bottom: '50px',
                right: '50px',
                zIndex: 10,
                '& .MuiSvgIcon-root': {
                    fontSize: '40px',
                },
                '& .MuiFab-root': {
                    padding: '25px',
                },
            }}
        >
            {/* <PinnedItems */}
            {/*    items={[ */}
            {/*        { */}
            {/*            url: '/backoffice/access/Q291cnNlVHlwZToyMQ==', */}
            {/*            name: 'google', */}
            {/*        }, */}
            {/*        { */}
            {/*            url: '/backoffice/content/Q291cnNlVHlwZToyMQ==', */}
            {/*            name: 'facebook', */}
            {/*        }, */}
            {/*    ]} */}
            {/* /> */}
            <Tooltip
                TransitionComponent={Zoom}
                sx={{
                    '&.MuiTooltip-tooltip': {
                        backgroundColor: 'red',
                        fontSize: '22px',
                    },
                }}
                title={
                    <Typography fontSize="20px">
                        {t('coachManageActions.editProfile')}
                    </Typography>
                }
                placement="top"
                onClick={onEditClick}
            >
                <Fab>
                    <AutoFixHigh />
                </Fab>
            </Tooltip>
            {/* <Tooltip */}
            {/*    title={ */}
            {/*        <Typography fontSize="20px"> */}
            {/*            {t('coachManageActions.cabinet')} */}
            {/*        </Typography> */}
            {/*    } */}
            {/* > */}
            {/*    <Fab */}
            {/*        color="primary" */}
            {/*        onClick={() => navigate(`../${backofficePaths.backoffice}`)} */}
            {/*    > */}
            {/*        <RoomPreferences /> */}
            {/*    </Fab> */}
            {/* </Tooltip> */}
        </Stack>
    )
}

export default OwnerPanel
