export const mapTo8DigitHex = (color: string, alpha: string) => color + alpha

export const alphaToHexadecimal = (alpha: number) => {
    const alphaInt = Math.round(alpha * 255)
    let hexadecimal = alphaInt.toString(16).toUpperCase()

    if (hexadecimal.length < 2) {
        hexadecimal = `0${hexadecimal}`
    }

    return hexadecimal
}

export const mapToGradient = (color: string, withVertical = true) => {
    return `linear-gradient(90deg, ${mapTo8DigitHex(
        color,
        alphaToHexadecimal(1)
    )} 0%, ${mapTo8DigitHex(
        color,
        alphaToHexadecimal(0)
    )} 60%, ${mapTo8DigitHex(
        color,
        alphaToHexadecimal(0)
    )} 100%), linear-gradient(0deg, ${mapTo8DigitHex(
        color,
        alphaToHexadecimal(1)
    )} 0%, ${mapTo8DigitHex(
        color,
        alphaToHexadecimal(0.35)
    )} 12%, ${mapTo8DigitHex(
        color,
        alphaToHexadecimal(0.18)
    )} 25%, ${mapTo8DigitHex(color, alphaToHexadecimal(0))} 42%)`
}
