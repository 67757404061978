import React from 'react'

import { Stack, Typography, useMediaQuery } from '@mui/material'
import { t } from 'i18next'

import { FeedbackPreview } from 'components/common'
import { useFeedbackList } from 'hooks/feedback'
import { FeedbackSessionType } from 'api/generated'
import { Loader } from '../index'

interface FeedbackListProps {
    session: FeedbackSessionType
}

const FeedbackList = ({ session }: FeedbackListProps): JSX.Element => {
    const lessThan700px = useMediaQuery('(max-width:700px)')

    const { items, loading } = useFeedbackList(session.id)

    if (loading) {
        return <Loader />
    }

    return (
        <Stack gap={lessThan700px ? '8px' : '15px'}>
            <Stack>
                <Typography
                    sx={theme => ({
                        textAlign: lessThan700px ? 'left' : 'right',
                        color: `${theme.colors.secondaryLight}`,
                        fontSize: 'max(min(2.5vw, 20px), 14px)',
                    })}
                >
                    {t('feedbackManager.skippedFeedbackUsersCount', {
                        count: session.feedbackCountSkipped as never,
                    })}
                </Typography>
            </Stack>
            {items.map(feedback => (
                <Stack
                    sx={theme => ({
                        border: `2px solid ${theme.colors.secondaryDark}`,
                        borderRadius: '8px',
                        padding: '10px',
                    })}
                    key={feedback.id}
                >
                    <FeedbackPreview feedback={feedback} />
                </Stack>
            ))}
        </Stack>
    )
}

export default FeedbackList
