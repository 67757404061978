import React from 'react'

import { createTheme } from '@mui/material/styles'

const background = '#181818'
const authBackground = '#000'
const white = '#fff'
const black = '#161616'

const primary = {
    primary: '#cbacf7',
    primaryLight: '#e2d5f6',
}

const secondary = {
    secondary: '#f0f0f0',
    secondaryDark: '#3a3a3a',
    secondaryLight: '#706f6f',
}

const error = {
    error: '#ff7979',
}

declare module '@mui/material/styles' {
    interface TypographyVariants {
        h1: React.CSSProperties
        s1: React.CSSProperties
        b1: React.CSSProperties
        c1: React.CSSProperties
    }

    interface TypographyVariantsOptions {
        h1: React.CSSProperties
        s1: React.CSSProperties
        b1: React.CSSProperties
        c1: React.CSSProperties
    }

    interface Theme {
        colors: {
            primary: React.CSSProperties['color']
            primaryLight: React.CSSProperties['color']
            secondary: React.CSSProperties['color']
            secondaryDark: React.CSSProperties['color']
            secondaryLight: React.CSSProperties['color']
            black: React.CSSProperties['color']
            white: React.CSSProperties['color']
            error: React.CSSProperties['color']
            background: React.CSSProperties['color']
            authBackground: React.CSSProperties['color']
        }
    }

    interface ThemeOptions {
        colors: {
            primary: React.CSSProperties['color']
            secondary: React.CSSProperties['color']
            error: React.CSSProperties['color']
            black: React.CSSProperties['color']
            white: React.CSSProperties['color']
            background: React.CSSProperties['color']
            authBackground: React.CSSProperties['color']
        }
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        h1: true
        s1: true
        b1: true
        c1: true
    }
}

export const theme = createTheme({
    palette: {
        primary: { main: primary.primary, light: primary.primaryLight },
        secondary: {
            main: secondary.secondary,
            dark: secondary.secondaryDark,
            light: secondary.secondaryLight,
        },
        error: { main: error.error },
        mode: 'dark',
    },
    colors: {
        ...primary,
        ...secondary,
        ...error,
        white,
        black,
        background,
        authBackground,
    },
    breakpoints: {
        values: {
            xs: 432,
            sm: 834,
            md: 1340,
            lg: 1500,
            xl: 1536,
        },
    },
    typography: {
        fontFamily: 'Roboto',
        h1: {
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '24px',
        },
        s1: {
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '24px',
        },
        b1: {
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '24px',
        },
        c1: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
        },
    },
})

export const appTheme = createTheme(theme, {
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    '& .MuiInputLabel-root': {
                        color: 'white',
                        backgroundColor: '#181818',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#6e6e6e',
                    },
                    '& .MuiInputBase-input': {
                        color: 'white',
                    },
                    '& .MuiButtonBase-root': {
                        color: '#6e6e6e',
                    },
                    '& .MuiFormHelperText-root': {
                        '&.Mui-error': {
                            background: '#ad4545',
                            color: 'white',
                            padding: '5px',
                            fontSize: '14px',
                            width: '100%',
                            top: '5px',
                            zIndex: 2,
                            position: 'relative',
                        },
                    },
                },
            },
            defaultProps: {
                variant: 'standard',
                fullWidth: true,
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    position: 'absolute',
                    bottom: '-25px',
                    left: 0,
                },
            },
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    textTransform: 'none',
                },
                textPrimary: {
                    color: theme.colors.black,
                    textTransform: 'none',
                },
            },
            defaultProps: {
                variant: 'contained',
                disableRipple: true,
                disableFocusRipple: true,
                disableElevation: true,
            },
        },
        MuiSkeleton: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.secondary.dark,
                    textDecoration: 'none',
                },
            },
        },
        MuiPopper: {
            styleOverrides: {
                root: {
                    textDecoration: 'none',
                    '& .MuiAutocomplete-listbox': {
                        msOverflowStyle: 'none',
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    cursor: 'pointer',
                    textDecoration: 'none',
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                color: 'secondary',
            },
        },

        MuiFormControl: {
            styleOverrides: {
                root: {},
            },
            defaultProps: {
                variant: 'standard',
            },
        },
    },
})
