import { useEffect, useState } from 'react'

import { FetchResult, useMutation, useQuery } from '@apollo/client'

import {
    SubmitFeedbackDocument,
    SubmitFeedbackMutation,
    MutationSubmitFeedbackArgs,
    ModuleType,
    ModuleListDocument,
    FeedbackListDocument,
    FeedbackType,
    FeedbackSessionListDocument,
    FeedbackSessionType,
    CreateFeedbackSessionDocument,
    MutationCreateFeedbackSessionArgs,
    CreateFeedbackSessionMutation,
} from 'api/generated'

export const useFeedbackAPI = () => {
    const [submitFeedback] = useMutation(SubmitFeedbackDocument)

    const submit = (data: MutationSubmitFeedbackArgs) => {
        return submitFeedback({ variables: data }).then(
            (response: FetchResult<SubmitFeedbackMutation>) => {
                return response?.data?.submitFeedback
            }
        )
    }

    return {
        submit,
    }
}

export const useFeedbackSessionAPI = () => {
    const [createSession] = useMutation(CreateFeedbackSessionDocument)

    const create = (data: MutationCreateFeedbackSessionArgs) => {
        return createSession({ variables: data }).then(
            (response: FetchResult<CreateFeedbackSessionMutation>) => {
                return response?.data?.createFeedbackSession
            }
        )
    }

    return {
        create,
    }
}

export const useFeedbackList = (feedbackSessionId: string) => {
    const [response, setResponse] = useState<FeedbackType[]>([])

    const { data, loading, error, refetch } = useQuery(FeedbackListDocument, {
        variables: { feedbackSessionId },
        skip: !feedbackSessionId,
    })

    useEffect(() => {
        if (data?.feedbackList) {
            const feedbackList: FeedbackType[] = data?.feedbackList.edges.map(
                edge => edge?.node as FeedbackType
            )
            setResponse(feedbackList)
        }
    }, [data])

    return {
        items: response,
        loading,
        error,
        refetch,
    }
}

export const useFeedbackSessions = (userId: string) => {
    const [response, setResponse] = useState<FeedbackSessionType[]>([])

    const { data, loading, error, refetch } = useQuery(
        FeedbackSessionListDocument,
        {
            variables: { userId },
            skip: !userId,
        }
    )

    useEffect(() => {
        if (data?.feedbackSessionList) {
            const feedbackSessions: FeedbackSessionType[] =
                data?.feedbackSessionList.edges.map(
                    edge => edge?.node as FeedbackSessionType
                )
            setResponse(feedbackSessions)
        }
    }, [data])

    return {
        feedbackSessions: response,
        loading,
        error,
        refetch,
    }
}
