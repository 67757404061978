import React from 'react'

import { t } from 'i18next'
import { Warning } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'

interface GlobalErrorProps {
    text?: string
    icon?: JSX.Element
}

const GlobalError = ({
    icon = <Warning />,
    text = t('globalErrors.somethingWentWrong') as string,
}: GlobalErrorProps) => {
    return (
        <Stack
            sx={{
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 999,
                '& .MuiSvgIcon-root': {
                    fontSize: '100px',
                    color: 'rgba(180,179,179,0.9)',
                    '@media (max-width:800px)': {
                        fontSize: '80px',
                    },
                },
            }}
            gap="25px"
        >
            {icon}
            <Typography
                sx={{
                    color: 'rgba(159,158,158,0.9)',
                    fontSize: '24px',
                    textAlign: 'center',
                    '@media (max-width:800px)': {
                        fontSize: '18px',
                    },
                    '@media (max-width:500px)': {
                        fontSize: '15px',
                    },
                }}
            >
                {text}
            </Typography>
        </Stack>
    )
}
export default GlobalError
