import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import {
    Box,
    Stack,
    Typography,
    Tooltip,
    Fab,
    useMediaQuery,
    TextField,
} from '@mui/material'
import { CheckCircleOutlineOutlined, CancelOutlined } from '@mui/icons-material'
import { isNull } from 'lodash'

import { SocialsList, Socials, OwnerPanel, Image } from 'components/common'
import { Header, GlobalError, Loader } from 'components/layout'
import { appPaths } from 'routes'
import { CourseType, UserType } from 'api/generated'
import { useDocumentTitle } from 'hooks/common'
import Settings from './components/Settings'
import {
    alphaToHexadecimal,
    mapTo8DigitHex,
    mapToGradient,
} from 'utils/colors.utils'

export interface State {
    name: string
    description: string | null
    background: string | null
}

interface EditablePageLayoutProps<T> {
    data: T
    type: 'course' | 'user'
    renderFooter?: (color?: string) => JSX.Element
    onSaveChanges: (data: State) => void
    editable?: boolean
    empty?: boolean
    loading?: boolean
    onNewBackground?: (file: File | null | undefined) => void
    onBackgroundLoaded: () => void
}

const EditablePageLayout = <T extends UserType | CourseType>({
    data,
    type,
    renderFooter,
    onSaveChanges,
    editable = false,
    empty = true,
    loading = true,
    onNewBackground,
    onBackgroundLoaded,
}: EditablePageLayoutProps<T>): JSX.Element => {
    const lessThan1400px = useMediaQuery('(max-width:1400px)')
    const lessThan1100px = useMediaQuery('(max-width:1100px)')
    const lessThan1000px = useMediaQuery('(max-width:1000px)')
    const lessThan800px = useMediaQuery('(max-width:800px)')
    const lessThan700px = useMediaQuery('(max-width:700px)')

    const [isEditing, setEditing] = useState(false)
    const [backgroundPreview, setBackgroundPreview] = useState(
        data?.background?.original
    )
    const [headerBackgroundColor, setHeaderBackgroundColor] =
        useState('#000000')
    const [backgroundGradientColor, setBackgroundGradientColor] =
        useState('#181818')
    const [textColor, setTextColor] = useState('#ffffff')

    useDocumentTitle(data.name)

    const navigate = useNavigate()

    const [newState, setNewState] = useState<State>({
        name: '',
        description: '',
        background: null,
    })

    if (isNull(data)) {
        return <GlobalError />
    }

    const handleGoToAuthor = () => {
        navigate(
            `../${appPaths.expert((data as CourseType).coach.id as string)}`
        )
    }

    const resolveSocials = () => {
        switch ((data as UserType).email) {
            case 'zloi@mail.com':
                return [
                    {
                        value: Socials.INSTAGRAM,
                        url: 'https://www.instagram.com/dmitriyzloi/',
                    },
                ]
            case 'cherma@mail.com':
                return [
                    {
                        value: Socials.INSTAGRAM,
                        url: 'https://www.instagram.com/cherma.ink/',
                    },
                    {
                        value: Socials.TIKTOK,
                        url: 'https://www.tiktok.com/@cherma.ink',
                    },
                ]
            case 'taisiyapav@mail.com':
                return [
                    {
                        value: Socials.INSTAGRAM,
                        url: 'https://www.instagram.com/taisiya_pavlyukova',
                    },
                ]
            default:
                return []
        }
    }

    const renderEditActions = () => {
        return (
            <Stack
                direction="row"
                sx={{
                    position: 'fixed',
                    bottom: '50px',
                    right: '50px',
                    zIndex: 10,
                }}
                gap="25px"
            >
                <Tooltip
                    title={
                        <Typography fontSize="18px">
                            {t('coachManageActions.cancelChanges')}
                        </Typography>
                    }
                    placement="top"
                >
                    <Fab
                        color="error"
                        sx={{
                            width: '56px',
                            height: '56px',
                            boxShadow:
                                '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
                            '& .MuiSvgIcon-root': {
                                fontSize: '40px',
                                color: 'black',
                            },
                            '& .MuiFab-root': {
                                padding: '25px',
                            },
                        }}
                        onClick={() => {
                            setEditing(false)
                            setNewState({
                                name: data?.name as string,
                                description: data?.description as string,
                                background: data?.background
                                    ?.original as string,
                            })
                            setBackgroundPreview(data?.background?.original)
                        }}
                    >
                        <CancelOutlined />
                    </Fab>
                </Tooltip>
                <Tooltip
                    title={
                        <Typography fontSize="18px">
                            {t('coachManageActions.saveChanges')}
                        </Typography>
                    }
                    placement="top"
                    onClick={() => {
                        setEditing(false)
                        onSaveChanges(newState)
                    }}
                >
                    <Fab
                        color="success"
                        sx={{
                            width: '56px',
                            height: '56px',
                            boxShadow:
                                '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
                            '& .MuiSvgIcon-root': {
                                fontSize: '40px',
                                color: 'black',
                            },
                            '& .MuiFab-root': {
                                padding: '25px',
                            },
                        }}
                    >
                        <CheckCircleOutlineOutlined />
                    </Fab>
                </Tooltip>
            </Stack>
        )
    }

    const renderBackgroundImage = () => {
        return (
            <Box
                sx={{
                    height: empty ? '100%' : lessThan700px ? 'auto' : '100vh',
                    width: lessThan700px ? 'calc(100% + 30px)' : '100%',
                    position: lessThan700px ? 'static' : 'absolute',
                    top: 0,
                    left: 0,
                    background: `${mapToGradient(
                        backgroundGradientColor
                    )} !important`,
                    ...(lessThan700px && {
                        overflow: 'hidden',
                        marginLeft: '-15px',
                        marginRight: '-15px',
                        maxHeight: '300px',
                        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.3)',
                        border: '1px solid #222',
                        background: 'rgba(0, 0, 0, 0.48)',
                    }),
                }}
            >
                <Image
                    onLoad={onBackgroundLoaded}
                    src={
                        backgroundPreview ||
                        (data?.background?.original as string)
                    }
                    alt="preview"
                    sx={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        position: lessThan700px ? 'static' : 'absolute',
                        zIndex: -1,
                    }}
                />
            </Box>
        )
    }

    const renderLoader = () => {
        return (
            <Box
                sx={{
                    backgroundColor: '#181818',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: '244',
                    height: '100vh',
                }}
            >
                <Loader />
            </Box>
        )
    }

    return (
        <Stack
            sx={{
                position: 'relative',
                padding: '15px',
                width: '100%',
            }}
        >
            <Header
                sx={{
                    background: `${mapTo8DigitHex(
                        headerBackgroundColor,
                        alphaToHexadecimal(0.48)
                    )} !important`,
                }}
            />
            {(backgroundPreview || data?.background) && renderBackgroundImage()}
            {loading && renderLoader()}
            <Stack
                sx={{
                    padding: '15% 80px 0',
                    position: 'relative',
                    overflow: 'hidden',

                    ...(lessThan1400px && {
                        padding: '15% 10px 0',
                    }),
                    ...(lessThan800px && {
                        padding: '5% 0',
                    }),
                }}
                gap="25px"
            >
                {isEditing && (
                    <Settings
                        sx={{
                            position: 'fixed',
                            top: '150px',
                            left: '25px',
                            '@media (max-width:1200px)': {
                                display: 'none',
                            },
                        }}
                        data={{
                            palette: {
                                header: {
                                    value: headerBackgroundColor,
                                    onChange: setHeaderBackgroundColor,
                                },
                                background: {
                                    value: backgroundGradientColor,
                                    onChange: setBackgroundGradientColor,
                                },
                                text: {
                                    value: textColor,
                                    onChange: setTextColor,
                                },
                            },
                            images: {
                                background: {
                                    value: backgroundPreview as string,
                                    onChange: (file, preview) => {
                                        setBackgroundPreview(preview)
                                        onNewBackground?.(file)
                                    },
                                },
                            },
                        }}
                    />
                )}
                <Stack
                    alignItems="flex-start"
                    gap={lessThan800px ? '5px' : '15px'}
                >
                    {type === 'course' && (
                        <Stack
                            direction="row"
                            alignItems="center"
                            padding="0 20px"
                            sx={theme => ({
                                transition: '.1s',
                                border: `1px solid transparent`,
                                '&:hover': {
                                    cursor: 'pointer',
                                    border: `1px solid ${theme.colors.primary}`,
                                },
                            })}
                            onClick={handleGoToAuthor}
                        >
                            <Typography
                                sx={{
                                    fontSize: '20px',
                                    letterSpacing: '-1px',
                                    textShadow: '2px 2px 2px #000',
                                    '@media (max-width:500px)': {
                                        paddingLeft: 0,
                                    },
                                    '& .MuiButtonBase-root': {
                                        padding: '5px',
                                        '@media (max-width:700px)': {
                                            padding: '5px',
                                        },
                                    },
                                }}
                            >
                                {(data as CourseType)?.coach?.name}
                            </Typography>
                        </Stack>
                    )}
                    <Stack
                        direction="column"
                        alignItems="flex-start"
                        gap="5px"
                        sx={{
                            maxWidth: '50%',
                            ...(lessThan1000px && {
                                maxWidth: '100%',
                            }),
                        }}
                    >
                        {type === 'user' && (
                            <SocialsList
                                color={textColor}
                                items={resolveSocials()}
                            />
                        )}
                        <TextField
                            fullWidth
                            variant="outlined"
                            disabled={!isEditing}
                            minRows={1}
                            placeholder={
                                type === 'course'
                                    ? (t('courseProfile.form.name') as string)
                                    : (t('coachProfile.form.name') as string)
                            }
                            sx={{
                                '& .MuiInputBase-root': {
                                    padding: '8px 16px',
                                    '& textarea': {
                                        '-webkit-text-fill-color': `${textColor} !important`,
                                    },
                                    '&.Mui-disabled': {
                                        '& textarea': {
                                            '-webkit-text-fill-color': `${textColor} !important`,
                                        },
                                        '& fieldset': {
                                            borderColor:
                                                'transparent !important',
                                        },
                                    },
                                },
                                '& .MuiInputBase-input': {
                                    padding: '0 4px',
                                    textShadow: '3px 3px 3px #000',
                                    fontSize: 'max(min(5vw, 102px), 42px)',
                                    lineHeight: 'max(min(5vw, 104px), 44px)',
                                    letterSpacing: '-6px',
                                    ...(lessThan1100px && {
                                        letterSpacing: '-4px',
                                    }),
                                },
                                ...(isEditing && {
                                    backgroundColor: 'rgba(0, 0, 0, .3)',
                                }),
                            }}
                            multiline
                            value={newState.name || (data.name as string)}
                            onChange={e =>
                                setNewState(prevState => ({
                                    ...prevState,
                                    name: e.target.value,
                                }))
                            }
                        />
                    </Stack>
                    <TextField
                        fullWidth
                        minRows={12}
                        variant="outlined"
                        disabled={!isEditing}
                        placeholder={
                            type === 'course'
                                ? (t(
                                      'courseProfile.form.description'
                                  ) as string)
                                : (t('coachProfile.form.description') as string)
                        }
                        sx={{
                            maxWidth: '600px',
                            '& .MuiInputBase-root': {
                                padding: '8px 16px',
                                '& textarea': {
                                    '-webkit-text-fill-color': `${textColor} !important`,
                                },
                                '&.Mui-disabled': {
                                    '& textarea': {
                                        '-webkit-text-fill-color': `${textColor} !important`,
                                    },
                                    '& fieldset': {
                                        borderColor: 'transparent !important',
                                    },
                                },
                            },
                            '& .MuiInputBase-input': {
                                padding: '0 4px',
                                fontSize: 'max(min(2vw, 19px), 15px)',
                                lineHeight: 'max(min(2vw, 22px), 18px)',
                                textShadow: '1px 1px 1px #000',
                            },
                            ...(isEditing && {
                                backgroundColor: 'rgba(0, 0, 0, .3)',
                            }),
                        }}
                        multiline
                        value={
                            newState.description || (data.description as string)
                        }
                        onChange={e =>
                            setNewState(prevState => ({
                                ...prevState,
                                description: e.target.value,
                            }))
                        }
                    />
                </Stack>
                <Box
                    sx={{
                        height: '2px',
                        position: 'relative',
                        zIndex: 1,
                        background:
                            'linear-gradient(90deg, rgba(228,217,217,0) 10%, rgba(163,163,163,1) 100%)',
                    }}
                />

                {renderFooter?.(textColor)}
            </Stack>

            {isEditing
                ? renderEditActions()
                : editable && (
                      <OwnerPanel onEditClick={() => setEditing(true)} />
                  )}
        </Stack>
    )
}

export default EditablePageLayout
