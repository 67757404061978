import React, { useRef } from 'react'

import { Box, Stack } from '@mui/material'

import { Input } from 'components/common'

interface ColorPickerProps {
    value: string
    onChange: (value: string) => void
}

const ColorPicker = ({ value, onChange }: ColorPickerProps) => {
    const ref = useRef<HTMLInputElement>(null)

    const handleClick = () => {
        if (ref.current) {
            ref.current?.click()
        }
    }

    return (
        <Stack>
            <Box
                sx={{
                    width: '25px',
                    height: '25px',
                    borderRadius: '50%',
                    border: `1px solid white`,
                    backgroundColor: value,
                    cursor: 'pointer',
                }}
                onClick={handleClick}
            />
            <Input
                ref={ref}
                type="color"
                value={value}
                sx={{
                    height: 0,
                    width: 0,
                    visibility: 'hidden',
                }}
                onChange={e => onChange(e.target.value)}
            />
        </Stack>
    )
}

export default ColorPicker
