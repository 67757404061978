import React from 'react'

import { t } from 'i18next'
import { Stack, SxProps, Typography } from '@mui/material'

import { ColorPicker } from 'components/inputs'
import { FilePicker } from 'components/common'

interface PaletteControl {
    value: string
    onChange: (value: string) => void
}

interface ImageControl {
    value: string | null | undefined
    onChange: (value: File | null | undefined, preview: string) => void
}

interface PaletteProps {
    data: {
        palette: {
            header: PaletteControl
            background: PaletteControl
            text: PaletteControl
        }
        images: {
            background: ImageControl
        }
    }
    sx?: SxProps
}

const Settings = ({ data, sx = {} }: PaletteProps): JSX.Element => {
    return (
        <Stack
            sx={{
                minWidth: '350px',
                padding: '15px',
                borderRadius: '8px',
                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.3)',
                border: '1px solid #222',
                background: 'rgba(0, 0, 0, 0.48)',
                backdropFilter: 'blur(5px)',
                zIndex: 2,
                ...sx,
            }}
            gap="25px"
        >
            <Stack gap="15px">
                <FilePicker
                    title={t('appearance.images.backgroundImage')}
                    value={data.images.background.value}
                    onChange={data.images.background.onChange}
                />
            </Stack>
            <Stack gap="10px">
                <Stack
                    direction="row"
                    gap="10px"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography>
                        {t('appearance.palette.headerColor')}
                    </Typography>
                    <ColorPicker
                        value={data.palette.header.value}
                        onChange={v => data.palette.header.onChange(v)}
                    />
                </Stack>
                <Stack
                    direction="row"
                    gap="10px"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography>
                        {t('appearance.palette.backgroundShadowColor')}
                    </Typography>
                    <ColorPicker
                        value={data.palette.background.value}
                        onChange={v => data.palette.background.onChange(v)}
                    />
                </Stack>
                <Stack
                    direction="row"
                    gap="10px"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography>{t('appearance.palette.textColor')}</Typography>
                    <ColorPicker
                        value={data.palette.text.value}
                        onChange={v => data.palette.text.onChange(v)}
                    />
                </Stack>
            </Stack>
        </Stack>
    )
}

export default Settings
