import {
    ApolloClient,
    ApolloLink,
    from,
    fromPromise,
    split,
    FetchResult,
    InMemoryCache,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { getMainDefinition } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client'

import { RefreshTokenDocument, RefreshTokenMutation } from 'api/generated'
import { API_ROOT, GRAPHQL_PATH } from 'config/env.config'
import { TokenStorage } from 'services'

const graphQlRoot = `${API_ROOT}${GRAPHQL_PATH}`

const httpLink = createUploadLink({
    uri: graphQlRoot,
})

const ApiLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
        credentials: 'include',
        headers: {
            ...headers,
            authorization: TokenStorage.isAuthenticated()
                ? TokenStorage.getAuthenticationHeader()
                : '',
        },
    }))

    return forward(operation)
})

const getNewTokenByRefreshToken = (refreshToken: string | null) => {
    return fromPromise(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        client
            .mutate({
                mutation: RefreshTokenDocument,
                variables: { refreshToken },
            })
            .then((response: FetchResult<RefreshTokenMutation>) => {
                if (response?.data) {
                    const result = response.data.refreshToken
                    if (result) {
                        const { token, refreshToken: newRefreshToken } = result
                        if (token && newRefreshToken) {
                            TokenStorage.storeToken(token)
                            TokenStorage.storeRefreshToken(newRefreshToken)
                            return token
                        }
                    }
                }
                return null
            })
            .catch(error => {
                if (
                    error.message === 'Invalid refresh token' ||
                    error.message === 'Refresh token is expired'
                ) {
                    TokenStorage.clear()
                    window.location.href = window.location.origin
                }
            })
    )
}

// eslint-disable-next-line consistent-return
const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
        const messages = graphQLErrors.map(({ message }) => message)
        if (
            messages.includes('Signature has expired') ||
            messages.includes('Error decoding signature')
        ) {
            return getNewTokenByRefreshToken(TokenStorage.getRefreshToken())
                .filter(value => Boolean(value))
                .flatMap(newToken => {
                    const oldHeaders = operation.getContext().headers
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    operation.setContext({
                        headers: {
                            ...oldHeaders,
                            authorization: `JWT ${newToken}`,
                        },
                    })
                    return forward(operation)
                })
        }
    }
})

const splitLink = split(({ query }) => {
    const definition = getMainDefinition(query)
    return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
    )
}, ApiLink.concat(httpLink))

const client = new ApolloClient({
    cache: new InMemoryCache({
        resultCaching: true,
        addTypename: true,
    }),
    connectToDevTools: process.env.NODE_ENV === 'development',
    link: from([errorLink, splitLink, ApiLink.concat(httpLink)]),
})

export { client }
