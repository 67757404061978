import React from 'react'

import { t } from 'i18next'
import {
    Box,
    Button,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    SxProps,
    Typography,
    useMediaQuery,
} from '@mui/material'
import { Login } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

import { Logo, Image } from 'components/common'
import { BrandButton } from 'components/buttons'
import { useMe } from 'hooks/user'
import { useAuth } from 'hooks/auth'
import { TokenStorage } from 'services'
import { appPaths, backofficePaths } from 'routes'
import noAvatar from 'assets/images/unknown_user.png'

interface HeaderProps {
    sx?: SxProps
}

const Header: React.FC<HeaderProps> = ({ sx = {} }: HeaderProps) => {
    const matches700px = useMediaQuery('(max-width:700px)')
    const matches600px = useMediaQuery('(max-width:600px)')
    const matches500px = useMediaQuery('(max-width:500px)')
    const { me } = useMe(!TokenStorage.isAuthenticated())
    const { logout } = useAuth()
    const navigate = useNavigate()

    const handleGoToProfile = () => {
        navigate(`../${appPaths.profile}`)
    }
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const isMenuOpen = Boolean(anchorEl)
    const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    const renderAuthMenu = () => {
        return (
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={isMenuOpen}
                onClose={handleCloseMenu}
                onClick={handleCloseMenu}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem
                    onClick={() =>
                        navigate(
                            `../${appPaths.login}?redirectTo=${window.location.href}`
                        )
                    }
                >
                    {t('auth.login')}
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => navigate(`../${appPaths.signup}`)}>
                    {t('auth.register')}
                </MenuItem>
            </Menu>
        )
    }

    return (
        <Box
            sx={{
                padding: '20px 25px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                marginBottom: '33px',
                borderRadius: '8px',
                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.3)',
                border: '1px solid #222',
                background: 'rgba(0, 0, 0, 0.48)',
                backdropFilter: 'blur(5px)',
                position: 'sticky',
                top: '25px',
                zIndex: 5,
                ...sx,
            }}
        >
            <Stack direction="row" alignItems="center" gap="100px">
                <Logo height={matches700px ? '40px' : undefined} />
            </Stack>
            <Stack
                direction="row"
                alignItems="normal"
                gap="50px"
                sx={{
                    zIndex: 2,
                }}
                divider={
                    <Divider
                        sx={{ borderWidth: '1px', height: 'auto' }}
                        orientation="vertical"
                    />
                }
            >
                <Stack direction="row" gap="15px" alignItems="center">
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {TokenStorage.isAuthenticated() ? (
                        <Stack direction="row" alignItems="center" gap="15px">
                            {/* <Button */}
                            {/*    // variant="outlined" */}
                            {/*    startIcon={<AutoAwesome />} */}
                            {/*    onClick={() => { */}
                            {/*        navigate(`../${appPaths.becomeExpert()}`) */}
                            {/*    }} */}
                            {/*    sx={ */}
                            {/*        { */}
                            {/*            // backgroundColor: '#ef7271', */}
                            {/*            // color: '#ef7271', */}
                            {/*        } */}
                            {/*    } */}
                            {/* > */}
                            {/*    {t('becomeExpert')} */}
                            {/* </Button> */}
                            {!matches700px && (
                                <Typography>{me?.email}</Typography>
                            )}
                            <Image
                                role="presentation"
                                sx={theme => ({
                                    borderRadius: '50%',
                                    border: '1px solid #000',
                                    width: '50px',
                                    height: '50px',
                                    objectFit: 'cover',
                                    cursor: 'pointer',
                                    '@media (max-width:700px)': {
                                        width: '30px',
                                        height: '30px',
                                    },
                                    '&:hover': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                })}
                                src={(me?.avatar as string) || noAvatar}
                                alt=""
                                onClick={handleGoToProfile}
                            />
                            {me?.role?.val === 'Coach' && (
                                <BrandButton
                                    onClick={() =>
                                        navigate(
                                            `../${backofficePaths.backoffice}`
                                        )
                                    }
                                >
                                    {t('navigation.backoffice') as string}
                                </BrandButton>
                            )}
                            {/* <IconButton onClick={logout}> */}
                            {/*    <Logout /> */}
                            {/* </IconButton> */}
                        </Stack>
                    ) : matches600px ? (
                        <>
                            <IconButton
                                onClick={handleClickMenu}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={
                                    isMenuOpen ? 'account-menu' : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={isMenuOpen ? 'true' : undefined}
                            >
                                <Login />
                            </IconButton>
                            {renderAuthMenu()}
                        </>
                    ) : (
                        <Stack
                            sx={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '15px',
                                '@media (max-width:700px)': {
                                    '& .MuiButtonBase-root': {
                                        padding: '4px 10px',
                                    },
                                },
                                '@media (max-width:500px)': {
                                    '& .MuiButtonBase-root': {
                                        padding: '4px 8px',
                                    },
                                },
                            }}
                        >
                            <Button
                                onClick={() =>
                                    navigate(
                                        `../${appPaths.login}?redirectTo=${window.location.href}`
                                    )
                                }
                            >
                                {t('auth.login')}
                            </Button>
                            {/* <Button onClick={handleClickSignUp}> */}
                            {/*    {t('auth.register')} */}
                            {/* </Button> */}
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </Box>
    )
}
export default Header
