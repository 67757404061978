import React from 'react'

import { Stack, LinearProgress, SxProps, useMediaQuery } from '@mui/material'

import { GlobalError } from 'components/layout'
import { useDocumentTitle } from 'hooks/common'

interface BackofficePageLayoutProps {
    loading?: boolean
    error?: boolean
    children: JSX.Element
    sx?: SxProps
    title: string
}

const BackofficePageLayout = ({
    error,
    loading,
    children,
    sx = {},
    title,
}: BackofficePageLayoutProps) => {
    const lessThan700px = useMediaQuery('(max-width:700px)')

    useDocumentTitle(title)

    if (loading) {
        return <LinearProgress />
    }

    if (error) {
        return <GlobalError />
    }

    return (
        <Stack
            sx={{
                padding: lessThan700px ? '25px 5px' : '50px 35px',
                ...sx,
            }}
        >
            {children}
        </Stack>
    )
}

export default BackofficePageLayout
