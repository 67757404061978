import '@fontsource/roboto/400.css'
import '@fontsource/roboto/900.css'
import '@fontsource/roboto/700.css'
import './index.scss'

import React from 'react'

import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { ApolloProvider } from '@apollo/client'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import initializeInternationalization from 'utils/internationalization'
import { client } from 'api'
import { appTheme } from 'theme'
import App from 'components/app'

declare global {
    interface Window {
        COMMIT_TAG: string
        __localeId__: string
    }
}

initializeInternationalization()

ReactDOM.render(
    <ThemeProvider theme={appTheme}>
        <ApolloProvider client={client}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SnackbarProvider
                    maxSnack={3}
                    autoHideDuration={5000}
                    hideIconVariant
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <StyledEngineProvider injectFirst>
                        <CssBaseline />
                        <BrowserRouter>
                            <Routes>
                                <Route path="/*" element={<App />} />
                            </Routes>
                        </BrowserRouter>
                    </StyledEngineProvider>
                </SnackbarProvider>
            </LocalizationProvider>
        </ApolloProvider>
    </ThemeProvider>,
    document.getElementById('root')
)
