import styles from './index.styles'

import React from 'react'

import { Button, ButtonProps, Typography } from '@mui/material'
import cx from 'classnames'

import { Loader } from '../../layout'

interface PrimaryButtonProps extends ButtonProps {
    extraClasses?: Partial<ReturnType<typeof styles>>
    active?: boolean
    loading?: boolean
}

const PrimaryButton = ({
    children,
    extraClasses,
    active,
    loading = false,
    ...rest
}: PrimaryButtonProps): JSX.Element => {
    const classes = styles()

    return (
        <Button
            variant="contained"
            className={cx(classes.root, extraClasses?.root, {
                [classes.active]: active,
            })}
            {...rest}
            disabled={loading}
        >
            {loading && <Loader />}
            <Typography
                variant="s1"
                className={cx(classes.text, extraClasses?.text)}
            >
                {children}
            </Typography>
        </Button>
    )
}

export default PrimaryButton
