import { useMutation, FetchResult } from '@apollo/client'

import {
    EmailSignInDocument,
    EmailSignInMutation,
    MutationEmailSignInArgs,
    SignUpDocument,
    SignUpMutation,
    MutationSignUpArgs,
    ResetPasswordDocument,
    ResetPasswordMutation,
    ResetPasswordInput,
    ResetPasswordConfirmDocument,
    ResetPasswordConfirmMutation,
    ResetPasswordConfirmInput,
    ActivateAccountDocument,
    ActivateAccountMutation,
    MutationActivateAccountArgs,
    CheckEmailTokenLinkDocument,
    CheckEmailTokenLinkInput,
    CheckEmailTokenLinkMutation,
    EmailConfirmDocument,
    EmailConfirmInput,
    EmailConfirmMutation,
} from 'api/generated'
import { client } from 'api'
import { TokenStorage } from 'services'

export const useAuth = () => {
    const [emailConfirm, { loading: confirmEmailIsProcessing }] =
        useMutation(EmailConfirmDocument)
    const [checkEmailToken, { loading: checkEmailTokenIsProcessing }] =
        useMutation(CheckEmailTokenLinkDocument)
    const [signInApi, { loading: loginIsProcessing }] =
        useMutation(EmailSignInDocument)
    const [activate] = useMutation(ActivateAccountDocument)
    const [signUpClient] = useMutation(SignUpDocument)

    const [resetPasswordApi, { loading: resetPasswordLoading }] = useMutation(
        ResetPasswordDocument
    )

    const [resetPasswordConfirmApi, { loading: resetPasswordConfirmLoading }] =
        useMutation(ResetPasswordConfirmDocument)

    const login = (data: MutationEmailSignInArgs, onSuccess?: () => void) => {
        return signInApi({ variables: data }).then(
            (response: FetchResult<EmailSignInMutation>) => {
                const result = response?.data?.emailSignIn
                if (result?.success) {
                    const { token, refreshToken } = result
                    TokenStorage.storeToken(token)
                    TokenStorage.storeRefreshToken(refreshToken)
                    if (onSuccess) {
                        onSuccess()
                    } else {
                        window.location.reload()
                        return client.resetStore().then(() => {
                            return result
                        })
                    }
                }
                return result
            }
        )
    }

    const verifyEmailToken = (data: CheckEmailTokenLinkInput) => {
        return checkEmailToken({ variables: { input: data } }).then(
            (response: FetchResult<CheckEmailTokenLinkMutation>) =>
                response?.data?.checkEmailTokenLink
        )
    }

    const confirmEmail = (data: EmailConfirmInput) => {
        return emailConfirm({ variables: { input: data } }).then(
            (response: FetchResult<EmailConfirmMutation>) =>
                response?.data?.emailConfirm
        )
    }

    const activateAccount = (data: MutationActivateAccountArgs) => {
        return activate({ variables: data }).then(
            (response: FetchResult<ActivateAccountMutation>) =>
                response?.data?.activateAccount
        )
    }

    const signUp = (data: MutationSignUpArgs) => {
        return signUpClient({ variables: data }).then(
            (response: FetchResult<SignUpMutation>) => response?.data?.signUp
        )
    }

    const logout = () => {
        TokenStorage.clear()
        localStorage.removeItem('role')
        window.location.reload()
    }

    const resetPassword = (data: ResetPasswordInput) => {
        return resetPasswordApi({ variables: { input: data } }).then(
            (response: FetchResult<ResetPasswordMutation>) =>
                response?.data?.resetPassword
        )
    }

    const resetPasswordConfirm = (data: ResetPasswordConfirmInput) => {
        return resetPasswordConfirmApi({ variables: { input: data } }).then(
            (response: FetchResult<ResetPasswordConfirmMutation>) =>
                response?.data?.resetPasswordConfirm
        )
    }

    return {
        login,
        signUp,
        logout,
        resetPassword,
        resetPasswordConfirm,
        activateAccount,
        verifyEmailToken,
        confirmEmail,
        isLoading:
            resetPasswordLoading ||
            resetPasswordConfirmLoading ||
            loginIsProcessing ||
            checkEmailTokenIsProcessing,
    }
}
