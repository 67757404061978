import { useEffect, useState } from 'react'

import { FetchResult, useMutation, useQuery } from '@apollo/client'

import {
    MeDocument,
    UserType,
    UserDetailsDocument,
    UserListDocument,
    UserUpdateDocument,
    MutationUserUpdateArgs,
    UserUpdateMutation,
} from 'api/generated'
import { ROLES } from '../config/user.config'

export const useMe = (skip = false) => {
    const [response, setResponse] = useState<UserType | null>(null)

    const { data, loading, error } = useQuery(MeDocument, { skip })

    useEffect(() => {
        if (data?.me) {
            setResponse(data?.me as UserType)
        }
    }, [data])

    return {
        me: response,
        loading,
        error,
    }
}

export const useUser = (id: string) => {
    const [response, setResponse] = useState<
        (UserType & { appearance: null }) | null
    >(null)

    const { data, loading, error, refetch } = useQuery(UserDetailsDocument, {
        variables: { id },
    })

    useEffect(() => {
        if (data?.userDetails) {
            setResponse(data?.userDetails as UserType & { appearance: null })
        }
    }, [data])

    return {
        user: response,
        loading,
        error,
        refetch,
    }
}

export const useUsers = (
    variables: { role?: ROLES; search?: string },
    skip = false
) => {
    const [response, setResponse] = useState<UserType[]>([])

    const { data, loading, error, refetch } = useQuery(UserListDocument, {
        variables: { ...variables, first: 10 },
        skip,
    })

    useEffect(() => {
        if (data?.userList) {
            const users: UserType[] = data?.userList.edges.map(
                edge => edge?.node as UserType
            )
            setResponse(users)
        }
    }, [data])

    return {
        users: response,
        loading,
        error,
        refetch,
    }
}

export const useUsersAPI = () => {
    const [updateUser, { loading }] = useMutation(UserUpdateDocument)

    const update = (data: MutationUserUpdateArgs) => {
        return updateUser({ variables: data }).then(
            (response: FetchResult<UserUpdateMutation>) => {
                return response?.data?.userUpdate
            }
        )
    }

    return {
        update,
        updateLoading: loading,
    }
}
